module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://androscoggin.lobstermarketing.com","noTrailingSlash":true,"noQueryString":true},
    },{
      plugin: require('../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Demo Androscoggin Lawn","short_name":"Demo Androscoggin Lawn","start_url":"/","theme_color":"#6b37bf","background_color":"#6b37bf","display":"standalone","icon":"src/images/lobster512.png","include_favicon":false,"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","cacheDigest":"3f3be65027a4504fdad4e3510ce002a8"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-breadcrumb/gatsby-browser.js'),
      options: {"plugins":[],"useAutoGen":true,"autoGenHomeLabel":"Home","exclude":["/dev-404-page/","/404/","/404.html"],"crumbLabelUpdates":[{"pathname":"/blog","crumbLabel":"Blog"},{"pathname":"/pest-library","crumbLabel":"Pest Library"},{"pathname":"/where-we-service","crumbLabel":"Where We Service"}]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-google-tagmanager-timeout/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-PHR5M4C","includeInDevelopment":false,"timeout":5000,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"page-change"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-70},
    },{
      plugin: require('../../gatsby-theme-androscoggin/gatsby-browser.js'),
      options: {"plugins":[],"projectId":"72120b26-cf48-00cb-487e-f955b77af8ea","authorizationKey":"ew0KICAiYWxnIjogIkhTMjU2IiwNCiAgInR5cCI6ICJKV1QiDQp9.ew0KICAianRpIjogIjVmMDliNTNmOWEyYTQzNDJhNGFjYjRmZjk3OWU2MTU3IiwNCiAgImlhdCI6ICIxNjMyMzM2MjUyIiwNCiAgImV4cCI6ICIxOTc3OTM2MjUyIiwNCiAgInByb2plY3RfaWQiOiAiNzIxMjBiMjZjZjQ4MDBjYjQ4N2VmOTU1Yjc3YWY4ZWEiLA0KICAidmVyIjogIjEuMC4wIiwNCiAgImF1ZCI6ICJwcmV2aWV3LmRlbGl2ZXIua2VudGljb2Nsb3VkLmNvbSINCn0.iMCM_s3xUKk0e6ExCETD7-gMDL6lp2WGZUSQB3CDRvM","usePreviewUrl":"false","whereWeServicePathName":"where-we-service","whereWeServiceBreadcrumb":"Where We Service","wWSActive":"1","siteUrl":"https://androscoggin.lobstermarketing.com","title":"Demo Androscoggin Lawn","description":"FeildRoutes","domainVerification":"meta-tag","gtmId":"GTM-PHR5M4C","compassID":"42a82a20fd60882a6cf4067db94368738701fce0d5ce61ec6c4845b74d313fbc","recaptchaSiteKey":"6LcGt9ohAAAAAFUrSNJ3mei-IMLU2nIk4NDVPtRQ","recaptchaSecretKey":"6LcGt9ohAAAAAFnvvurHbl1k923sR4SzZA-mHEe5","pestLibraryPathName":"pest-library","pestLibraryBreadcrumb":"Pest Library"},
    },{
      plugin: require('../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
